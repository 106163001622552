import { ClickAwayListener } from '@mui/base';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Typography, Grid, Paper, Popover, Button } from '@mui/material';
import { Container } from 'postcss';
import DOMPurify from 'dompurify';
const parse = require('html-react-parser');
const ConfigDOMPurify = require('../../config/DOMP');


const StyledPaper = styled(Paper)({
  '&.MuiPaper-root': {
    padding: '1.3rem',
    top: '354px',
    left: '1404px',
    maxHeight: '13rem',
    maxWidth: '25rem',
    borderRadius: '0px',
    position: 'absolute',
    border: '1px solid #025f99',
    color: '#626262'
  }
});

const Details = (props) => {
  const { t } = useTranslation();
  const summaryParsed = parse(props.TicketJourneyPage.serviceDescription);
  const [summary, setSummary] = useState('');
  const [url, setUrl] = useState('');
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openToolTip = Boolean(anchorEl);
  const toolTipPrioritytLink =
    process.env.REACT_APP_DEFINITION_OF_PRIORITY_DOCUPEDIA;

  let statusTicket = props.TicketJourneyPage.status; 
  statusTicket = String(statusTicket).toLocaleLowerCase();
  if(statusTicket == 'closed' || statusTicket == 'resolved' || statusTicket == 'completed' || statusTicket == 'rejected' || statusTicket == 'cancelled') {
    statusTicket = 'closed';
  }

  const handleToolTipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToolTipClose = () => {
    setAnchorEl(null);
  };

  const ticketType = () => {
    let typeString = String(props.TicketJourneyPage.type);
    if (typeString !== '') {
      typeString = typeString.charAt(0).toUpperCase() + typeString.slice(1);
    }
    return typeString;
  };

  const calculatePersonalUrgency = () => {
    if (props.source == 'smt') {
      let message = '';
      props.TicketJourneyPage.priority !== 'low'
        ? (message = t('ui_spear.ticketDetails.I_cant_work'))
        : (message = t('ui_spear.ticketDetails.I_can_work'));
      return message;
    } else {
      return '-';
    }
  };

  const handleClick = () => {
    window.open(url, '_blank', 'noreferrer');
  };

  useEffect(() => {
    const getActionLinkBySource = () => {
      switch (props.source) {
        //source from Service Now
        case 'new':
          setUrl(
            `${process.env.REACT_APP_SNOW_SP_URL}/sp?id=ticket&table=sc_req_item&sys_id=${props.TicketJourneyPage.sys_id}&view=sp`
          );
          setShowMoreDetails(true);
          break;
        //When Source is from OLD ITSP
        case 'old':
          setUrl(
            `${process.env.REACT_APP_CPSC_URL}/RequestCenter/ordermanagement/reqentrystatus.do?query=requisitionentrystatus&reqid=${props.TicketJourneyPage.activity_id}&reqentryid=${props.TicketJourneyPage.reqEntryId}&formAction=displayEntryStatus&performerID=&serviceid=${props.TicketJourneyPage.serviceId}&requisitionId=${props.TicketJourneyPage.activity_id}`
          );
          setShowMoreDetails(true);
          break;
        //source from SMT
        default:
          setShowMoreDetails(false);
          break;
      }
    };

    getActionLinkBySource();
  }, [props.TicketJourneyPage]);

  useEffect(() => {
    let summaryText = '';
    let summaryPart = '';
    // Variable used when user is not sap key user, smt payload doesnt contain props.rawTicketData.Detailed_Decription in that case, preveting from breaking.
    let summaryRawData =  props.rawTicketData?.Detailed_Decription ? props.rawTicketData.Detailed_Decription[0].replace(/(?:\r\n|\r|\n)/g, '<br/>') : '';

    if (props.smtTicketSummary.length > 0) {
      props.smtTicketSummary.map((workDetail, index) => {
        if (props.SAPKeyUser) {
          let date = new Date(workDetail.Submit_Date[0]);
          summaryPart = '';
          summaryPart =
            date.getUTCDate() +
            '.' +
            (date.getUTCMonth() + 1) +
            '.' +
            date.getUTCFullYear() +
            ', ' +
            date.getUTCHours() +
            ':' +
            date.getUTCMinutes() +
            ':' +
            date.getUTCSeconds() +
            ' UTC, ';
          summaryPart = summaryPart + workDetail.Submitter[0] + ', ';
          summaryPart = summaryPart + workDetail.Work_Info_Type[0] + '<br/>';
          if (typeof workDetail.Notes == 'string') {
            summaryPart = summaryPart + workDetail.Notes + '<br/>'
          } else {
            summaryPart = summaryPart + workDetail.Notes[0].replace(/(?:\r\n|\r|\n)/g, '<br/>') +
              '<br/>';
          }
          summaryText = summaryText + summaryPart;
        } else {
          summaryPart = summaryRawData +
            '<br/>';
          summaryText = summaryPart;
        }

      });
      setSummary(summaryText);
    } else {
      summaryPart = summaryRawData + '<br/>';
      summaryText = summaryPart;
      setSummary(summaryText);
    }
  }, [props.smtTicketSummary]);

  const ToolTip = () => {
    return (
      <>
        <ClickAwayListener onClickAway={handleToolTipClose}>
          <StyledPaper onClose={handleToolTipClose}>
            <div className="flex">
              <h4 className="text-[20px] mr-[-12rem] font-medium font-Bosch_sans_light">
                {t('ui_spear.myTickets.Priority_for_company')}
              </h4>
              <span
                className="bosch-icon-np cursor-pointer ml-[18rem] text-[20px] font-normal"
                onClick={handleToolTipClose}
              >
                &#xe0f0;
              </span>
            </div>
            <div className="mt-[1rem]">
              <Typography className="text-[13px]">
                {t('ui_spear.ticketDetails.Details_PriorityEvaluated')}&nbsp;
                <a href={toolTipPrioritytLink}>
                  {t('ui_spear.ticketDetails.Details_PriorityLevels')}
                </a>
              </Typography>
            </div>
          </StyledPaper>
        </ClickAwayListener>
      </>
    );
  };

  const formatedDate = (originalDate, useFormat, type) => {
    var months = [
      t('ui_spear.myTickets.January'),
      t('ui_spear.myTickets.February'),
      t('ui_spear.myTickets.March'),
      t('ui_spear.myTickets.April'),
      t('ui_spear.myTickets.May'),
      t('ui_spear.myTickets.June'),
      t('ui_spear.myTickets.July'),
      t('ui_spear.myTickets.August'),
      t('ui_spear.myTickets.September'),
      t('ui_spear.myTickets.October'),
      t('ui_spear.myTickets.November'),
      t('ui_spear.myTickets.December')
    ];
    const date = new Date(originalDate);
    if (!isNaN(date.getDate())) {
      const day = date.getDate().toString();
      const month = months[date.getMonth()];
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const strTime = `${hours}:${minutes} ${ampm}`;
      const monthIndex = months.indexOf(month);

      if (!useFormat) {
        if (type == 'lastDateTime') {
          return `${date.getFullYear()}/${monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1
            }/${day < 10 ? '0' + day : day} ${strTime}`;
        } else {
          return `${date.getFullYear()}/${monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1
            }/${day < 10 ? '0' + day : day} ${strTime}`;
        }
      } else {
        return `${date.getFullYear()}/${month}/${day < 10 ? '0' + day : day
          } ${strTime}`;
      }
    }
  };

  return (
    <div>
      <div className="py-10 w-4/5 mx-auto">
        <h4 className="font-Bosch_sans_light py-2 text-[28.1px]">
          {t('ui_spear.createTicket.Ticket_details')}
        </h4>
        <div className="font-Bosch_sans_light py-10">
          <div className="grid grid-cols-3">
            <div>
              <p className="grid grid-cols-3">
                <span className="font-Bosch_sans_bold">{`${t(
                  'ui_spear.myTickets.Type'
                )}:`}</span>
                <span>{ticketType()}</span>
              </p>
              <p className="grid grid-cols-3 pt-5">
                <span className="font-Bosch_sans_bold">{`${t(
                  'ui_spear.ticketDetails.Details_Cost'
                )}:`}</span>
                <span>
                  {props.TicketJourneyPage.cost == 0
                    ? '-'
                    : `${props.TicketJourneyPage.cost} EUR`}
                </span>
              </p>
            </div>
            <div>
              <p className="grid grid-cols-3">
                <span className="font-Bosch_sans_bold">{`${t(
                  'ui_spear.ticketDetails.Details_Created'
                )}:`}</span>
                <span>
                  {props.source == 'smt'
                    ? formatedDate(
                      props.TicketJourneyPage.originalSubmittedDate,
                      false
                    )
                    : props.TicketJourneyPage.submittedDate}
                </span>
              </p>
              <p className="grid grid-cols-3 pt-5">
                <span className="font-Bosch_sans_bold">
                {props.source == 'smt'
                  ? statusTicket == 'closed'
                    ?
                      `${t('ui_spear.ticketDetails.Details_Closed')}:`
                    :
                      `${t('ui_spear.ticketDetails.Details_LastUpdate')}:`
                  : `${t('ui_spear.ticketDetails.Details_Closed')}:`
                }
                </span>
                <span>
                  {props.source == 'smt'
                    ? formatedDate(
                      props.TicketJourneyPage.originalLastDateTime,
                      false,
                      'lastDateTime'
                    )
                    : props.source == 'new' ? props.TicketJourneyPage.closedDate : props.TicketJourneyPage.lastDateTime}
                </span>
              </p>
            </div>
            <div>
              <p className="grid grid-cols-3">
                <span className="font-Bosch_sans_bold">{`${t(
                  'ui_spear.myTickets.Priority_for_company'
                )}:`}</span>
                <span className="capitalize">
                  {props.TicketJourneyPage.priority} &nbsp;
                  <span
                    className="cursor-pointer"
                    onClick={handleToolTipClick}
                    style={{
                      fontFamily: 'bosch-icon',
                      fontWeight: 400,
                      color: '#005691'
                    }}
                  >
                    &#xe1ac;
                  </span>
                  {openToolTip && <ToolTip />}
                </span>
              </p>
              <p className="grid grid-cols-3 pt-5">
                <span className="font-Bosch_sans_bold">{`${t(
                  'ui_spear.ticketDetails.Details_PersonalUrgency'
                )}:`}</span>
                <span>{calculatePersonalUrgency()}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 w-4/5 mx-auto">
        <h4 className="font-Bosch_sans_light py-2 text-[28.1px]">
          {t('ui_spear.ticketDetails.Details_Summary')}
        </h4>
        <div className="overflow-y-auto h-[300px] border p-3">
          {props.source === 'smt' ? (
            <p
              dangerouslySetInnerHTML={{ 
                __html: DOMPurify.sanitize(summary, ConfigDOMPurify.DOMPurifyConfiguration) 
              }}
              className="py-5 leading-loose font-Bosch_sans_light"
            ></p>
          ) : (
            <p className="py-5 leading-loose font-Bosch_sans_light">
              {Number(props.TicketJourneyPage.ticketId)
                ? parse(String(summaryParsed))
                : summaryParsed}
            </p>
          )}
        </div>
        {showMoreDetails && (
          <Button
            onClick={handleClick}
            style={{
              display: props.source == 'smt' ? 'none' : 'flex',
              textTransform: 'flex'
            }}
            className="border border-change_cust_btn rounded-none text-[13px] mr-4 p-3 h-10 hover:text-hover_blue_btns hover:border-hover_blue_btns normal-case font-Bosch_sans_light"
            variant="outlined"
          >
            {t('ui_spear.ticketDetails.See_More_Details')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Details;
