export const getTeamsUrl = (countryCode) => {
  const defaultTeamsMapping = '{"DE":"tip.de@de.bosch.com", "AT":"tip.de@de.bosch.com", "CH":"tip.de@de.bosch.com"}';
  let teamsMapping;

  try {
    const teamsMappingEnv = process.env.REACT_APP_TEAMS_EMAIL_MAPPING ?? defaultTeamsMapping; // Fallback for environment variable
    teamsMapping = JSON.parse(teamsMappingEnv);
  } catch (error) {
    console.error("Failed to parse variable: REACT_APP_TEAMS_EMAIL_MAPPING");
    teamsMapping = JSON.parse(defaultTeamsMapping); // Fallback to default mapping
  }

  // Look up the Teams email using the country code (default to DE if not found)
  const teamsEmail = teamsMapping[countryCode.toUpperCase()] || "tip.de@de.bosch.com"; // Default email if not found
  const baseEmailURL = process.env.REACT_APP_TEAMS_BASE_URL ?? 'https://teams.microsoft.com/l/call/0/0?users=';

  // Return the full Teams URL
  return `${baseEmailURL}${teamsEmail}`;
};

/**
 * Validate if the user has access to the Teams beta URL based on their country.
 * @param {Object} userInfo - The user information object, containing ntId, location, and other details.
 * @returns {boolean} - True if authorized, false otherwise.
 */
export const validateTeamsURL = (userInfo) => {
  // Get the mapping from the environment variable
  const defaultTeamsMapping = '{"DE":"tip.de@de.bosch.com", "AT":"tip.de@de.bosch.com", "CH":"tip.de@de.bosch.com"}';
  let teamsMapping;

  try {
    const teamsMappingEnv = process.env.REACT_APP_TEAMS_EMAIL_MAPPING ?? defaultTeamsMapping; // Fallback for environment variable
    teamsMapping = JSON.parse(teamsMappingEnv);
  } catch (error) {
    console.error("Failed to parse variable: REACT_APP_TEAMS_EMAIL_MAPPING");
    teamsMapping = JSON.parse(defaultTeamsMapping); // Fallback to default mapping
  }

  // Check if the user's country is part of the entitled countries
  return userInfo.userCountry.toUpperCase() in teamsMapping; // Returns true if the country exists in the mapping
};